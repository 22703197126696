import { getPropsFor404 } from "utils/staticHelpers";
import { Page } from "ineo/components/Page";
import { PageNotFound } from "shared/components/PageNotFound";

export async function getStaticProps({ locale }) {
  return await getPropsFor404({ locale, preview: false });
}

const PageToExport = ({ button, footer, navigation, title, text }) => (
  <Page footer={footer} navigation={navigation} text={text} title={title}>
    <PageNotFound button={button} title={title} text={text} />
  </Page>
);

export default PageToExport;
